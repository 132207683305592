import styled from "styled-components";
import moment from "moment";
import { dayNames } from "../../utils/index";

const StyledTable = styled.table`
  caption-side: top;
  border: 1px solid;
  border-collapse: collapse;
  cursor: pointer;
  /* border: transparent; */
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */
  box-shadow: var(--shadow-3);
  text-transform: capitalize;
  caption-side: bottom;
  height: 100%;
  width: 100%;
  justify-content: center;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  tbody {
    vertical-align: top;
  }
  td,
  th {
    border: none;
  }
  td,
  th {
    border: 1px solid;
    border-color: var(--black);
    padding: 5px;
    font-size: 12px;
  }

  td {
    border: 1px solid;
    padding: 5px 10px;
  }

  tbody tr {
    border: 1px solid;
    :nth-of-type(odd) {
      background-color: var(--primary-200);
    }
    :hover {
      background-color: var(--grey-100);
      box-shadow: var(--shadow-1);
    }
  }
  thead > tr {
    background-color: var(--primary-600);
    color: var(--white);
    font-family: "Sans-Serif";
  }

  caption {
    font-size: 0.9em;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    color: var(--primary-600);
  }
`;

export default ({ timeStamp }) => (
  <TableMarkup
    // titles={Object.keys(data[0])}
    // data={data}
    timeStamp={timeStamp}
  />
);

const TableMarkup = ({ timeStamp }) => (
  <StyledTable>
    <caption>Grouplease About Timestamp</caption>
    <thead>
      <tr>
        {/* {titles.map((title, index) => (
          <th key={index}>{title}</th>
        ))} */}
        <th>วันที่</th>
        <th>สถานที่</th>
        <th>เวลา เข้า</th>
        <th>เวลา ออก</th>
        <th>สาย/น.</th>
      </tr>
    </thead>
    <tbody>
      {timeStamp.map((item, index) => (
        <tr key={index}>
          <td>
            {moment(item.time_date_key).format("DD ")}
            {dayNames[moment(item.time_date_key).format("dddd")]}
            {/* {monthNames[parseInt(moment(item.time_date_key).format("MM")) - 1]} */}
            {/* {moment(item.time_date_key).format(" YYYY")} */}
          </td>
          <td>{item.address.substring(1, 18)}...</td>
          <td>{item.time_in}</td>
          <td>{item.time_out}</td>
          <td style={{ textAlign: "center" }}>{item.minuteLeave}</td>
        </tr>
      ))}
    </tbody>
  </StyledTable>
);
