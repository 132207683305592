import styled from "styled-components";

const Wrapper = styled.article`
  padding: 0.5rem;
  background: var(--white);
  border-radius: var(--borderRadius);
  border-bottom: 5px solid ${(props) => props.color};
  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .count {
    display: block;
    font-weight: 500;
    font-size: 16px;
    margin-left: 5px;
    color: ${(props) => props.color};
  }
  .title {
    margin: 0;
    /* text-transform: capitalize; */
    /* letter-spacing: var(--letterSpacing); */
    text-align: left;
    margin-top: 0.1rem;
  }
  .icon {
    width: 50px;
    height: 40px;
    background: ${(props) => props.bcg};
    border-radius: var(--borderRadius);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 1.5rem;
      color: ${(props) => props.color};
    }
  }
`;

export default Wrapper;
