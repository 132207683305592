import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormRowDatetime = ({
  name,
  value,
  labelText,
  showIcon,
  excludeDateIntervals,
  withPortal,
  ...other
}) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label-small">
        {labelText || name}
      </label>
      <DatePicker
        className="form-input"
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        name={name}
        selected={value}
        showIcon={showIcon || false}
        excludeDateIntervals={excludeDateIntervals}
        withPortal={withPortal | true}
        {...other}
      />
    </div>
  );
};

export default FormRowDatetime;
