const flexMessageLeave = ({
  runno,
  userName,
  pictureUrl,
  leaveType,
  leaveName,
  daysNumber,
  tmpBalance,
  timeStamp,
}) => [
  {
    type: "flex",
    altText: "Leave success",
    contents: {
      type: "bubble",
      header: {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "text",
            text: `ลา สำเร็จ No.${runno} ...!`,
            weight: "bold",
            size: "lg",
            color: "#FFFFFFFF",
          },
        ],
      },
      hero: {
        type: "image",
        url: pictureUrl,
        size: "full",
        aspectRatio: "20:13",
        aspectMode: "cover",
        action: {
          type: "uri",
          label: "Action",
          uri: "https://linecorp.com/",
        },
      },
      body: {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "text",
                text: `${userName}`,
                weight: "bold",
                size: "md",
                color: "#000000FF",
              },
            ],
          },
          {
            type: "separator",
            margin: "sm",
            color: "#27C110FF",
          },
          {
            type: "box",
            layout: "vertical",
            margin: "md",
            contents: [
              {
                type: "box",
                layout: "baseline",
                contents: [
                  {
                    type: "text",
                    text: `ลา ${leaveType}`,
                    size: "sm",
                    color: "#AAAAAA",
                    flex: 1,
                  },
                  {
                    type: "text",
                    text: `${leaveName}`,
                    size: "sm",
                    color: "#666666",
                    flex: 5,
                  },
                ],
              },
              {
                type: "box",
                layout: "baseline",
                contents: [
                  {
                    type: "text",
                    text: "จำนวน",
                    size: "sm",
                    color: "#AAAAAA",
                    flex: 1,
                  },
                  {
                    type: "text",
                    text: `${daysNumber} วัน , วันลาใช้ได้ ${tmpBalance} วัน`,
                    size: "sm",
                    color: "#666666",
                    flex: 5,
                  },
                ],
              },
            ],
          },
          {
            type: "box",
            layout: "vertical",
            margin: "md",
            contents: [
              {
                type: "text",
                text: `วันที่ทำรายการ : ${timeStamp} น.`,
                weight: "bold",
                size: "xs",
                color: "#666666",
                margin: "none",
              },
            ],
          },
        ],
      },
      styles: {
        header: {
          backgroundColor: "#00BA76",
        },
        body: {
          backgroundColor: "#F0F4F8",
        },
      },
    },
  },
];

module.exports = flexMessageLeave;
