import { useState, useEffect } from "react";
import { FiUsers } from "react-icons/fi";

import liff from "@line/liff";
import moment from "moment";
import WrapperView from "../assets/wrappers/View";
import WrapperLeaveContainer from "../assets/wrappers/LeaveContainer";
import WrapperStatItem from "../assets/wrappers/StatItem";
import { useAppContext } from "../context/appContext";
import {
  FormRowSelect,
  FormRowDatetime,
  Alert,
  FormRowRadio,
} from "../components";
import { monthNames, leaveType, calculateBusinessDays } from "../utils/index";

const disabledDateRanges = [
  {
    start: new Date(moment().startOf("year")),
    end:
      moment().format("MM") === "01"
        ? new Date(moment().startOf("year").format("YYYY-MM-DD"))
        : new Date(moment(moment()).subtract(1, "months").format("YYYY-MM-DD")),
  },
];

const initState = {
  userId: "",
  eId: "",
  leaveType: "",
  leaveName: "",
  dateStart: "",
  dateEnd: "",
  daysNumber: 0,
  beginTime: "",
  endTime: "",
  hour: "00:00",
  isfullDay: true,
  fullDayText: "fullDay",
  date: moment(),
  profileLine: null,
  OS: "",
};

const Leave = () => {
  const {
    isLoading,
    showAlert,
    displayAlert,
    displayAlertText,
    craeteUserLeave,
    userDisplay,
    userVacation,
    loadData,
  } = useAppContext();

  const [values, setValues] = useState(initState);

  const clearValues = () => {
    setValues(initState);
  };

  // const handleChange = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };

  const handleFullDayChange = (e) => {
    console.log(e.target);
    const { value } = e.target;
    let { beginTime, endTime, hour } = "";
    let isfullDay;
    let dayDiff;

    const isDay = values.dateStart && values.dateEnd ? true : false;
    console.log(isDay);
    if (isDay) {
      // dayDiff =
      //   moment(moment(values.dateEnd).format("YYYY-MM-DD")).diff(
      //     moment(values.dateStart).format("YYYY-MM-DD"),
      //     "days"
      //   ) + 1;
      dayDiff = calculateBusinessDays(values.dateStart, values.dateEnd);
    }
    console.log(dayDiff);

    if (value === "fullDay") {
      beginTime = "";
      endTime = "";
      hour = "00:00";
      isfullDay = true;
    } else if (value === "haftDayMorning") {
      beginTime = "08:30";
      endTime = "12:30";
      hour = "04:00";
      isfullDay = false;
      dayDiff = 0;
    } else if (value === "haftDayAfternoon") {
      beginTime = "13:30";
      endTime = "17:30";
      hour = "04:00";
      isfullDay = false;
      dayDiff = 0;
    }
    setValues({
      ...values,
      beginTime: beginTime,
      endTime: endTime,
      hour: hour,
      isfullDay: isfullDay,
      fullDayText: value,
      daysNumber: dayDiff === undefined || dayDiff <= 0 ? 0 : dayDiff,
    });
  };

  const handleDateChange = (update, name) => {
    {
      console.log("update :", update);
      let { dateStart, dateEnd } = "";
      let dayDiff;
      if (update[0]) {
        dateStart = update[0];
      }
      if (update[1]) {
        dateEnd = update[1];
      }
      // console.log(moment(update[0]).format("YYYY-MM-DD HH:mm:ss"));
      // console.log(moment(update[1]).format("YYYY-MM-DD HH:mm:ss"));

      const isDay = dateStart && dateEnd ? true : false;

      if (isDay) {
        // dayDiff =
        //   moment(moment(dateEnd).format("YYYY-MM-DD")).diff(
        //     moment(dateStart).format("YYYY-MM-DD"),
        //     "days"
        //   ) + 1;
        dayDiff = calculateBusinessDays(dateStart, dateEnd);
      }

      if (values.isfullDay === false) {
        dayDiff = 0;
      }

      //console.log("dateStart", dateStart);
      //console.log("dateEnd", dateEnd);
      //console.log(dayDiff);

      //console.log(dayDiff === undefined || dayDiff <= 0 ? 0 : dayDiff);

      setValues({
        ...values,
        dateStart: dateStart,
        dateEnd: dateEnd,
        daysNumber: dayDiff === undefined || dayDiff <= 0 ? 0 : dayDiff,
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const {
      userId,
      dateStart,
      dateEnd,
      daysNumber,
      beginTime,
      endTime,
      hour,
      leaveType,
      leaveName,
      profileLine,
      OS,
      isfullDay,
    } = values;

    const eid = userDisplay.user_eid;

    if (!eid || !leaveType || !dateStart || !dateEnd) {
      // console.log(eid);
      displayAlert();
      return;
    }

    if (!userDisplay.userCompanyCode) {
      // console.log(eid);
      displayAlert();
      return;
    }

    if (
      moment(dateStart).format("YYYY-MM-DD") >
      moment(dateEnd).format("YYYY-MM-DD")
    ) {
      displayAlertText({ alertText: "วันเริ่มต้น > ถึงวันที่สุดท้าย" });
      return;
    }

    if (
      !isfullDay &&
      moment(dateStart).format("YYYY-MM-DD") !==
        moment(dateEnd).format("YYYY-MM-DD")
    ) {
      displayAlertText({
        alertText: "ลาครึ่งวัน วันที่ - ถึง วันที่ ต้องวันเดียวกัน",
      });
      return;
    }

    if (
      ["2", "พ"].includes(leaveType) &&
      Number(daysNumber) > Number(userVacation.tmpTotal)
    ) {
      // console.log(Number(daysNumber));
      // console.log(Number(userVacation.tmpTotal));
      displayAlertText({
        alertText: `วันลา เหลือ ${userVacation.tmpTotal} วัน`,
      });
      return;
    }

    const currentUserLeave = {
      userId: userId,
      eid: eid,
      dateStart: moment(dateStart).format("YYYY-MM-DD"),
      dateEnd: moment(dateEnd).format("YYYY-MM-DD"),
      daysNumber,
      beginTime,
      endTime,
      hour,
      leaveType,
      leaveName,
      timeStamp: moment(new Date()).format("DD-MM-YYYY HH:mm:ss"),
      profileLine,
      OS,
      isfullDay,
    };

    craeteUserLeave({
      currentUserLeave,
      endPoint: "addleave",
      alertText: "ลา สำเร็จ กำลังปิดหน้าเวป !...",
      liff,
    });
  };

  const FetchData = async (profile, accessToken) => {
    loadData({
      profile,
      endPoint: "getuser",
      alertText: "กำลังโหลด ข้อมูล รอสักครู่ ...!!",
    });
  };

  useEffect(() => {
    return () => {
      liff.init({ liffId: "1654928550-Z5epx0r3" }).then(() => {
        if (liff.isLoggedIn()) {
          const accessToken = liff.getAccessToken();
          liff.getProfile().then((profile) => {
            FetchData(profile, accessToken);
            setValues({
              ...values,
              userId: profile.userId,
              profileLine: profile,
              OS: liff.getOS(),
            });
          });
        } else {
          liff.login({});
        }
      });
    };
  }, []);

  return (
    <WrapperView>
      <div className="block bcg-black">
        {/* <p style={{ color: "white" }}>{JSON.stringify(values)}</p> */}
      </div>
      <div className="block">
        <form
          className="form-viewAll"
          style={{
            marginTop: "-300px",
          }}
          onSubmit={onSubmit}
        >
          <WrapperStatItem color="#007a00" bcg="#9cd494">
            <header>
              <span className="icon">
                <FiUsers className="nameOfTheClass" />
              </span>
              <span className="count">
                welcome, {userDisplay.user_eid} {userDisplay.user_fullname}
              </span>
            </header>
            <div style={{ marginTop: "5px" }}>
              <p className="user-text-small">
                ประจำเดือน : {monthNames[parseInt(moment().format("MM")) - 1]}
                {moment(values.date).locale("th").format(" YYYY")}
              </p>
              <p className="user-text-small">
                บริษัท : {userDisplay.userCompanyCode}
              </p>
              {/* <p className="user-text-small">
                Department : {userDisplay.user_department} ,{" "}
                {userDisplay.user_team}
              </p> */}
              <p className="user-text-small">
                วันที่เริ่ม ลาพักร้อน {userVacation.startDateVacation}
              </p>
              <p className="user-text-small">
                วันลาพักร้อน :{userVacation.vacationTotal} วัน , คงเหลือ :
                {userVacation.vacationBalance} วัน , รออนุมัติ :
                {userVacation.vacationPre} วัน , วันลาใช้ได้ :
                {userVacation.tmpTotal} วัน
              </p>
              <p
                className="user-text-small"
                style={{ color: "red", fontWeight: 600 }}
              >
                ป่วย : {userVacation.tmpSick} วัน
              </p>
              {showAlert && <Alert />}
              <hr className="rounded" />
            </div>
            <FormRowSelect
              labelText="ประเภทการลา"
              name="leaveType"
              value={values.leaveType || ""}
              handleChange={(e) => {
                setValues({
                  ...values,
                  [e.target.name]: e.target.value,
                  leaveName: leaveType[e.target.selectedIndex].title,
                });
              }}
              list={leaveType}
            />
            <FormRowDatetime
              labelText="วันที่ - ถึง วันที่"
              name="dateRange"
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/yyyy - dd/mm/yyyy"
              selectsRange={true}
              startDate={values.dateStart}
              endDate={values.dateEnd}
              isClearable
              disabledKeyboardNavigation
              todayButton={"Today"}
              withPortal
              excludeDateIntervals={disabledDateRanges}
              // filterDate={(date) => date.getDay() !== 6 && date.getDay() !== 0}
              filterDate={(date) => date.getDay() !== 0}
              onChange={(update) => handleDateChange(update, "dateRange")}
            />
            <WrapperLeaveContainer>
              <div className="form-center">
                {/* <FormRowDatetime
                  labelText="วันที่"
                  name="dateStart"
                  selectsStart
                  selected={values.dateStart}
                  startDate={values.dateStart}
                  endDate={values.dateEnd}
                  excludeDateIntervals={disabledDateRanges}
                  todayButton={"Today"}
                  onChange={(date) => handleDateChange(date, "dateStart")}
                />
                <FormRowDatetime
                  labelText="วันที่"
                  name="dateEnd"
                  selectsEnd
                  selected={values.dateEnd}
                  startDate={values.dateStart}
                  endDate={values.dateEnd}
                  excludeDateIntervals={disabledDateRanges}
                  todayButton={"Today"}
                  onChange={(date) => handleDateChange(date, "dateEnd")}
                /> */}
              </div>
              <div className="form-center">
                <FormRowRadio
                  type="radio"
                  labelText="เต็มวัน"
                  name="isFullDay"
                  value="fullDay"
                  checked={values.fullDayText === "fullDay"}
                  handleChange={handleFullDayChange}
                />
                <FormRowRadio
                  type="radio"
                  labelText="ครึ่งเช้า"
                  name="isFullDay"
                  value="haftDayMorning"
                  checked={values.fullDayText === "haftDayMorning"}
                  handleChange={handleFullDayChange}
                />
                <FormRowRadio
                  type="radio"
                  labelText="ครึ่งบ่าย"
                  name="isFullDay"
                  value="haftDayAfternoon"
                  checked={values.fullDayText === "haftDayAfternoon"}
                  handleChange={handleFullDayChange}
                />
              </div>
              <div style={{ marginLeft: "5px", marginTop: "10px" }}>
                <p
                  className="user-text"
                  style={{ color: "red", marginTop: "5px" }}
                >
                  {values.daysNumber > 0
                    ? `จำนวน วันลา : ${values.daysNumber} วัน`
                    : "..."}
                  <br />
                  {/* {JSON.stringify(userDisplay)}
                  <br />
                  <br />
                  {JSON.stringify(userVacation)}
                  <br />
                  <br />
                  {JSON.stringify(userVacationData)}
                  <br />
                  <br />
                  {JSON.stringify(values)} */}
                </p>
              </div>
              <hr className="rounded" />

              <div className="btn-container">
                <button
                  className="btn btn-block submit-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "loading..." : "บันทึก"}
                </button>
                <button
                  className="btn btn-block clear-btn"
                  type="button"
                  onClick={() => {
                    clearValues();
                    //liff.closeWindow();
                  }}
                >
                  {isLoading ? "loading..." : "เคลียร์"}
                </button>
              </div>
            </WrapperLeaveContainer>
            <div
              className="btn-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "5px",
              }}
            >
              <button
                type="button"
                className="btn clear-btn"
                // style={{ width: "40%" }}
                onClick={() => {
                  liff.closeWindow();
                }}
              >
                ปิด
              </button>
            </div>
          </WrapperStatItem>
        </form>
      </div>
    </WrapperView>
  );
};

export default Leave;
