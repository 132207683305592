export const DISPLAY_ALERT = "SHOW_ALERT";
export const DISPLAY_ALERT_TEXT = "SHOW_ALERT_TEXT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";

export const CREATE_LEAVE_BEGIN = "CREATE_LEAVE_BEGIN";
export const CREATE_LEAVE_SUCCESS = "CREATE_LEAVE_SUCCESS";
export const CREATE_LEAVE_ERROR = "CREATE_USER_ERROR";

export const SETUP_USER_BEGIN_LOAD_DATA = "SETUP_USER_BEGIN_LOAD_DATA";
export const SETUP_USER_LOAD_DATA = "SETUP_USER_LOAD_DATA";
