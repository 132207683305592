import { useState, useEffect } from "react";
import liff from "@line/liff";
import { FiUsers } from "react-icons/fi";
import moment from "moment";
import { Alert } from "../components";
import WrapperView from "../assets/wrappers/View";
import WrapperStatsContainer from "../assets/wrappers/StatsContainer";
import WrapperStatItem from "../assets/wrappers/StatItem";
import WrapperTable from "../assets/wrappers/Table";
import { monthNames } from "../utils/index";
import { useAppContext } from "../context/appContext";

import { TimeLeaveText } from "../utils/index";

const ViewAll = () => {
  const { showAlert, userDisplay, userTimestamp, loadData } = useAppContext();
  const [values, setValues] = useState({
    id: "",
    date: moment(),
  });

  const FetchData = async (profile, accessToken) => {
    loadData({
      profile,
      endPoint: "getuser",
      alertText: "กำลังโหลด ข้อมูล รอสักครู่ ...!!",
    });
  };

  useEffect(() => {
    return () => {
      liff.init({ liffId: "1654928550-BpRd3Ywm" }).then(() => {
        if (liff.isLoggedIn()) {
          const accessToken = liff.getAccessToken();
          liff.getProfile().then((profile) => {
            FetchData(profile, accessToken);
          });
        } else {
          liff.login({});
        }
      });
    };
  }, []);

  return (
    <WrapperView>
      <div className="block bcg-black">
        <p style={{ color: "white" }}></p>
      </div>
      <div className="block">
        <div
          className="form-viewAll"
          // style={{
          //   marginTop: "-300px",
          // }}
        >
          <WrapperStatsContainer>
            {/*  color="#14919b" bcg="#bef8fd" */}
            <WrapperStatItem color="#007a00" bcg="#9cd494">
              <header>
                <span className="icon">
                  <FiUsers className="nameOfTheClass" />
                </span>
                <span className="count">
                  welcome, {userDisplay.user_eid} {userDisplay.user_fullname}
                </span>
              </header>
              <div style={{ marginTop: "5px" }}>
                <p className="user-text">
                  ประจำเดือน : {monthNames[parseInt(moment().format("MM")) - 1]}
                  {moment(values.date).locale("th").format(" YYYY")}
                </p>
                <p className="user-text">
                  แผนก : {userDisplay.user_department} , {userDisplay.user_team}
                </p>
                <p
                  className="user-text-small"
                  style={{ color: "red", fontWeight: 600 }}
                >
                  สาย : {userDisplay.late} วัน ,{" "}
                  {TimeLeaveText(userDisplay.minuteLate)}
                </p>
                {showAlert && <Alert />}
                <hr className="rounded"></hr>
              </div>
              <div>
                <WrapperTable timeStamp={userTimestamp} />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  type="button"
                  className="btn clear-btn"
                  // style={{ width: "40%" }}
                  onClick={() => {
                    liff.closeWindow();
                  }}
                >
                  ปิด
                </button>
              </div>
            </WrapperStatItem>
          </WrapperStatsContainer>
        </div>
      </div>
    </WrapperView>
  );
};

export default ViewAll;
