import {
  DISPLAY_ALERT,
  DISPLAY_ALERT_TEXT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  CREATE_LEAVE_BEGIN,
  CREATE_LEAVE_SUCCESS,
  CREATE_LEAVE_ERROR,
  SETUP_USER_BEGIN_LOAD_DATA,
  SETUP_USER_LOAD_DATA,
} from "./actions";

const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: "danger",
      alertText: "กรุณา คีย์ข้อมูล ให้ครบ !",
    };
  }
  if (action.type === DISPLAY_ALERT_TEXT) {
    return {
      ...state,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: "",
      alertText: "",
    };
  }

  if (action.type === SETUP_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === SETUP_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CREATE_LEAVE_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_LEAVE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === CREATE_LEAVE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === SETUP_USER_BEGIN_LOAD_DATA) {
    return {
      ...state,
      isLoading: true,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }

  if (action.type === SETUP_USER_LOAD_DATA) {
    return {
      ...state,
      isLoading: false,
      //showAlert: true,
      //alertType: "success",
      userDisplay: action.payload.userDisplay,
      userTimestamp: action.payload.userTimestamp,
      userVacation: action.payload.userVacation,
      userVacationData: action.payload.userVacationData,
      //alertText: action.payload.alertText,
    };
  }

  throw new Error(`no such action : ${action.type}`);
};

export default reducer;
