import { useState, useEffect } from "react";
import axios from "axios";
import liff from "@line/liff";
import moment from "moment";
import { FormRow, Alert } from "../components";
import Wrapper from "../assets/wrappers/RegisterPage";
import { useAppContext } from "../context/appContext";

const ChangePassword = () => {
  const { isLoading, showAlert, updatePassword } = useAppContext();

  const [values, setValues] = useState({
    id: "",
    userName: "",
    password: "",
    newPassword: "",
    userId: "",
    accessToken: "",
    isVisible: true,
  });
  const [user, setUser] = useState([]);

  const FetchData = (profile, accessToken) => {
    const userId = profile.userId;
    // console.log("profile", profile);
    // console.log("accessToken", accessToken);
    axios
      .put(`${process.env.REACT_APP_API}/api/v1/auth/getuser`, {
        userId: userId,
      })
      .then((res) => {
        console.log(res.data);
        const { userResult } = res.data;
        setUser(userResult);
        setValues({
          ...values,
          id: userResult.user_eid,
          userName: userResult.user_fullname,
          password: userResult.user_password,
          newPassword: userResult.user_gl_password,
          userId: profile.userId,
          accessToken: accessToken,
        });
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    return () => {
      liff.init({ liffId: "1654928550-gP6Xz1Pr" }).then(() => {
        if (liff.isLoggedIn()) {
          const accessToken = liff.getAccessToken();
          liff.getProfile().then((profile) => {
            FetchData(profile, accessToken);
          });
        } else {
          liff.login({});
        }
      });
    };
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    //console.log("change Password");

    const { id, userId, password, newPassword, accessToken, userName } = values;
    const currentUser = {
      id,
      userId,
      password,
      newPassword,
      accessToken,
      userName,
    };
    if (true) {
      updatePassword({
        currentUser,
        endPoint: "updatePassword",
        alertText: "เปลี่ยนรหัสผ่าน เสร็จเรียบร้อยเเล้ว...",
        liff,
      });
    }
  };

  return (
    <Wrapper>
      <div className="block bcg-black">
        <p style={{ color: "white" }}>
          {/* {JSON.stringify(user)} */}
          {/* {JSON.stringify(values)} */}
        </p>
      </div>
      <div className="block">
        <div className="container" style={{ marginTop: "-150px" }}>
          <form className="form" onSubmit={onSubmit}>
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: "12px",
                marginTop: "-15px",
                marginLeft: "-25px",
                marginBottom: "5px",
              }}
            >
              *** กรุณาเปลี่ยนรหัสผ่าน ที่ระบบ GL ก่อน <br />
              *** เเล้ว มาอัพเดท ที่ระบบไลน์ ...!! <br />
              *** รหัสผ่าน หมดอายุ วันที่{" "}
              {moment(user.user_password_expire).format("DD-MM-YYYY")}
            </p>
            {showAlert && <Alert />}
            <p
              className="user-title"
              style={{ textAlign: "left", marginTop: "-2px" }}
            >
              พนักงาน : {user.user_eid} {user.user_fullname}
            </p>
            <hr style={{ marginTop: "10px" }} />

            <FormRow
              type={values.isVisible ? "text" : "password"}
              labelText="รหัสผ่านเก่า (lineTimestamp)"
              name="password"
              value={values.password}
              handleChange={handleChange}
              readOnly={true}
            />
            <FormRow
              type={values.isVisible ? "text" : "password"}
              labelText="รหัสผ่านใหม่ (GL เขียว)"
              name="newPassword"
              value={values.newPassword}
              handleChange={handleChange}
              readOnly={true}
            />

            <button
              type="submit"
              className={
                values.password.trim() === values.newPassword.trim()
                  ? "btn btn-block clear-btn"
                  : "btn btn-block"
              }
              disabled={
                values.password.trim() === values.newPassword.trim()
                  ? true
                  : false
              }
              style={{ marginTop: "5px" }}
            >
              {isLoading ? "`กำลังโหลด ข้อมูล..." : "อัพเดท รหัสผ่าน"}
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default ChangePassword;
