import moment from "moment";

const dayNames = {
  Monday: "จันทร์",
  Tuesday: "อังคาร",
  Wednesday: "พุธ",
  Thursday: "พฤหัสบดี",
  Friday: "ศุกร์",
  Saturday: "เสาร์",
  Sunday: "อาทิตย์",
};

const monthNames = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม.",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const leaveType = [
  { id: "", title: "" },
  { id: "1", title: "ป่วย" },
  { id: "2", title: "พักร้อน(ล่วงหน้า)" },
  { id: "พ", title: "พักร้อน(ย้อนหลัง)" },
  { id: "3", title: "คลอด" },
  { id: "ค", title: "คลอดเกิน 45 วัน" },
  // { id: "4", title: "เกณฑ์ทหาร" },
  { id: "5", title: "อื่นๆ" },
  { id: "6", title: "ไม่รับค่าจ้าง" },
  { id: "7", title: "บวช" },
  // { id: "8", title: "ปฎิบัติงานนอกสถานที่" },
  // { id: "9", title: "ขาดงาน" },
  // { id: "ศ", title: "พิธีศพ" },
  { id: "ก", title: "ลากิจ" },
  // { id: "H", title: "WFH" },
];

function TimeLeaveText(minuteLate) {
  let { hour, minute } = 0;
  hour = Math.floor(minuteLate / 60);
  minute = minuteLate % 60.0;
  console.log(hour, minute);
  hour = hour === undefined ? 0 : hour;
  minute = minute === undefined ? 0 : minute;
  let text = `เป็นเวลา ${hour} ชั่วโมง ${minute} นาที `;

  return text;
}

function calculateBusinessDays(dateStart, dateEnd) {
  const days =
    moment(moment(dateEnd).format("YYYY-MM-DD")).diff(
      moment(dateStart).format("YYYY-MM-DD"),
      "days"
    ) + 1;

  let newDay = moment(moment(dateStart).format("YYYY-MM-DD")).add(-1, "days");
  let workingDays = 0;
  let sundays = 0;
  let saturdays = 0;
  let isoWeekday = 0;
  // console.log("dateEnd :", moment(dateEnd).format("YYYY-MM-DD"));
  // console.log("dateStart :", moment(dateStart).format("YYYY-MM-DD"));
  // console.log("days :", days);

  for (let i = 0; i < days; i++) {
    newDay = moment(newDay).add(1, "days");
    isoWeekday = moment(newDay).isoWeekday();
    // console.log("i :", i);
    // console.log("newDay :", moment(newDay).format("YYYY-MM-DD"));
    // console.log("isoWeekday :", moment(newDay).isoWeekday());
    const isWeekend = [7].includes(isoWeekday); //6,

    if (!isWeekend) {
      workingDays++;
    } else {
      // if (isoWeekday === 6) saturdays++;
      if (isoWeekday === 7) sundays++;
    }
  }
  //   const day = newDay.getDay();
  //   newDay = d1.add(1, "days").toDate();
  //   const isWeekend = day % 6 === 0;
  //   if (!isWeekend) {
  //     workingDays++;
  //   } else {
  //     if (day === 6) saturdays++;
  //     if (day === 0) sundays++;
  //   }
  // }
  console.log(
    "Total Days:",
    days,
    "workingDays",
    workingDays,
    "saturdays",
    saturdays,
    "sundays",
    sundays
  );
  return workingDays;
}

export {
  dayNames,
  monthNames,
  leaveType,
  TimeLeaveText,
  calculateBusinessDays,
};
