import { useState, useEffect } from "react";
import { FormRowSelect, FormRow, Alert } from "../components";
import Wrapper from "../assets/wrappers/RegisterPage";
import { useAppContext } from "../context/appContext";
import liff from "@line/liff";

const initialState = {
  id: "",
  password: "",
  pictureUrl: "",
  displayName: "",
  userId: "",
  idToken: "",
  accessToken: "",
  statusMessage: "",
  OS: "",
  email: null,
  department: "",
  isMember: true,
};

const departmentOptions = [
  { id: "", title: "" },
  { id: "1", title: "IT" },
  { id: "2", title: "การเงิน" },
  { id: "3", title: "บัญชี" },
  { id: "4", title: "จัดซื้อ" },
  { id: "5", title: "การตลาด" },
  { id: "6", title: "กลุ่มกฎหมาย" },
  { id: "7", title: "เลขานุการบริษัท" },
  { id: "8", title: "กลุ่มทรัพยากรบุคคล" },
  { id: "9", title: "ตรวจสอบภายใน" },
  { id: "10", title: "กลุ่มการขายและปฏิบัติการ GL" },
  { id: "11", title: "กลุ่มการขายและปฏิบัติการ TNB" },
  { id: "12", title: "บริการทางโทรศัพท์" },
  { id: "13", title: "แนะนำผลิตภัณฑ์ทางโทรศัพท์	" },
  { id: "14", title: "ประกันภัย" },
  { id: "15", title: "ทะเบียน" },
  { id: "16", title: "สินเชื่อรถจักรยานยนต์ใหม่" },
  { id: "17", title: "สินเชื่อรถจักรยานยนต์มือสอง" },
  { id: "18", title: "ติดตามหนี้ทางโทรศัพท์" },
  { id: "19", title: "ควบคุมหนี้มีปัญหา" },
  { id: "20", title: "ควบคุมหนี้มีปัญหา 1" },
  { id: "21", title: "ควบคุมหนี้มีปัญหา 2" },
  { id: "22", title: "เร่งรัดหนี้สิน" },
  { id: "23", title: "นิติกรรมสัญญา" },
  { id: "24", title: "ธุรการ" },
  { id: "25", title: "ธุรการ GL" },
  { id: "26", title: "การประมูล" },
  { id: "27", title: "โกดัง" },
  { id: "28", title: "จัดการยานยนต์	" },
  { id: "29", title: "พัฒนาธุรกิจ" },
  { id: "30", title: "Group Report Analysis" },
  { id: "31", title: "กลุ่มสนับสนุนผู้บริหาร" },
];

const Register = () => {
  const [values, setValues] = useState(initialState);

  const { isLoading, showAlert, displayAlert, setupUser } = useAppContext();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      id,
      password,
      displayName,
      pictureUrl,
      userId,
      idToken,
      accessToken,
      email,
      department,
      isMember,
    } = values;
    console.log(id, password, department);
    if (!id || !password || !department || !displayName) {
      displayAlert();
      return;
    }
    const currentUser = {
      id,
      password,
      displayName,
      pictureUrl,
      userId,
      idToken,
      accessToken,
      email,
      department,
      isMember,
    };
    if (isMember) {
      setupUser({
        currentUser,
        endPoint: "register",
        alertText: "ลงทะเบียนสำเร็จ! ...",
        liff: liff,
      });
      //.then(
      // setTimeout(() => {
      //   liff.closeWindow();
      //   registerSuccess({ currentUser, endPoint: "regissuccess" });
      // }, 1000)
      //);
    }
  };

  const initLine = () => {
    //1660988204-pa9qwDMJ
    liff.init({ liffId: "1654928550-MXWO2Rbq" }).then(() => {
      console.log(liff);
      const idToken = liff.getIDToken();
      const accessToken = liff.getAccessToken();
      if (liff.isLoggedIn()) {
        liff.getProfile().then((profile) => {
          console.log(profile);
          console.log(accessToken);
          setValues({
            ...values,
            pictureUrl: profile.pictureUrl,
            displayName: profile.displayName,
            userId: profile.userId,
            idToken: idToken,
            accessToken: accessToken,
            OS: liff.getOS(),
            email: liff.getDecodedIDToken().email || undefined,
            statusMessage: profile.statusMessage,
          });
        });
      } else {
        liff.login();
      }
    });
  };

  useEffect(() => {
    return () => initLine();
  }, []);

  return (
    <Wrapper>
      <div className="block bcg-black"> </div>
      <div className="block">
        <div className="container" style={{ marginTop: "-250px" }}>
          <form className="form" onSubmit={onSubmit}>
            {isLoading}
            <div style={{ textAlign: "center" }}>
              {/* <img
                src={
                  values.pictureUrl !== "" ? values.pictureUrl : defaultImage
                }
                alt="user"
                className="user-img"
              /> */}
            </div>
            {showAlert && <Alert />}
            {/* <p className="user-title">รายละเอียด</p> */}

            <FormRow
              type="text"
              labelText="รหัส พนักงาน"
              name="id"
              value={values.id}
              handleChange={handleChange}
            />
            <FormRow
              type="password"
              labelText="รหัสผ่าน"
              name="password"
              value={values.password}
              handleChange={handleChange}
            />
            <FormRowSelect
              name="department"
              labelText="แผนก"
              value={values.department}
              handleChange={handleChange}
              list={departmentOptions}
            />

            <p className="user-value" style={{ textAlign: "left" }}>
              ไลน์ ดิสเพลย์ : {values.displayName}
            </p>
            <p className="user-value" style={{ textAlign: "left" }}>
              ไลน์ ไอดี : {values.userId.substring(0, 22)} ...
            </p>
            {/* <p className="user-value" style={{ textAlign: "left" }}>
              OS : {values.OS}
            </p>
            <p className="user-value" style={{ textAlign: "left" }}>
              Email : {values.email ? values.email : "undefined"}
            </p>*/}
            {/* <p className="user-value" style={{ textAlign: "left",fontSize:"10px" }}>
              Status : {values.accessToken}
            </p>  */}

            <button
              type="submit"
              className="btn btn-block"
              disabled={isLoading}
            >
              {isLoading ? "loading..." : "สมัคร"}
            </button>
            <button
              type="button"
              className="btn btn-block btn-hipster"
              disabled={isLoading}
              onClick={() => {
                liff.closeWindow();
              }}
            >
              {isLoading ? "loading..." : "ปิด"}
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};
export default Register;
