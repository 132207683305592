import React, { useReducer, useContext } from "react";

import reducer from "./reducer";

import { monthNames } from "../utils/index";
import moment from "moment";
import axios from "axios";
import {
  DISPLAY_ALERT,
  DISPLAY_ALERT_TEXT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  CREATE_LEAVE_BEGIN,
  CREATE_LEAVE_SUCCESS,
  CREATE_LEAVE_ERROR,
  SETUP_USER_BEGIN_LOAD_DATA,
  SETUP_USER_LOAD_DATA,
} from "./actions";

// flex
const { flexMessageLeave } = require("../assets/flexMessage/index");

const initialState = {
  userLoading: true,
  isLoading: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  user: null,
  userDisplay: [],
  userTimestamp: [],
  userVacation: [],
  userVacationData: [],
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };

  const displayAlertText = ({ alertText }) => {
    dispatch({ type: DISPLAY_ALERT_TEXT, payload: { alertText } });
    clearAlert();
  };

  const clearAlert = () => {
    //console.log(state);
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 2300);
  };

  const setupUser = async ({ currentUser, endPoint, alertText, liff }) => {
    dispatch({ type: SETUP_USER_BEGIN });
    console.log(currentUser, endPoint);
    console.log(process.env.REACT_APP_API);

    const { id, password, idToken, accessToken, department } = currentUser;
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/${endPoint}`,
        // currentUser
        { id, password, idToken, accessToken, department }
      );
      console.log(data);
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { alertText },
      });

      setTimeout(() => {
        liff.closeWindow();
        registerSuccess({ currentUser, endPoint: "regisflex" });
      }, 1000);
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updatePassword = async ({ currentUser, endPoint, alertText, liff }) => {
    dispatch({ type: SETUP_USER_BEGIN });
    // console.log(currentUser, endPoint);

    // มี  server  ค่อย เอาเข้ามา
    const { id, userId, password, newPassword } = currentUser;
    try {
      const { data } = await axios.patch(
        `${process.env.REACT_APP_API}/api/v1/auth/${endPoint}`,
        { id, userId, password, newPassword }
      );

      console.log("data:", data);
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { alertText },
      });

      setTimeout(() => {
        liff.closeWindow();
        updateSuccess({ currentUser, endPoint: "updateflex" });
      }, 1000);
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const registerSuccess = async ({ currentUser, endPoint }) => {
    // console.log(currentUser, endPoint);
    const { idToken, accessToken, email, id, userId, password } = currentUser;
    console.log(currentUser, endPoint);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/${endPoint}`,
        {
          idToken,
          accessToken,
          email,
          id,
          userId,
          password,
        }
      );
      console.log(data);
      // เพื่อ respon เอามาทำ อะไร
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error },
      });
    }
  };

  const updateSuccess = async ({ currentUser, endPoint }) => {
    // console.log(currentUser, endPoint);
    const { idToken, accessToken, id, userId, userName } = currentUser;
    console.log(currentUser, endPoint);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/${endPoint}`,
        {
          idToken,
          accessToken,
          id,
          userId,
          userName,
        }
      );
      console.log(data);
      // เพื่อ respon เอามาทำ อะไร
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error },
      });
    }
  };

  const loadData = async ({ profile, endPoint, alertText }) => {
    dispatch({
      type: SETUP_USER_BEGIN_LOAD_DATA,
      payload: {
        alertText,
      },
    });
    const userId = profile.userId;
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/auth/${endPoint}`,
        {
          userId: userId,
        }
      );

      // console.log(" userDisplay: ", data.userResult);
      // console.log("userTimestamp:", data.userTimestamp[0]);
      // console.log("userVacation:", data.userVacation.vacation[0]);
      // console.log(" userVacationData:", data.userVacation.data);

      const vacation = data.userVacation.vacation[0];
      const vacationData = data.userVacation.data;

      let { startDateVacation, vacationTotal, vacationBalance, vacationUse } =
        "";
      let { total, balance, use } = 0;
      if (vacation) {
        startDateVacation = `${moment(vacation.VACA_BGNZ).format("DD")} ${
          monthNames[parseInt(moment(vacation.VACA_BGNZ).format("MM")) - 1]
        } ${moment(vacation.VACA_BGNZ).locale("th").format("YYYY")}`;

        use = vacation.VACA_BAL_T;
        total = vacation.VACA_BGN_D;
        balance = vacation.VACA_BAL_T;
        vacationTotal = Number(total / 480.0).toFixed(1);
        vacationBalance = Number(balance / 480.0).toFixed(1);
        vacationUse = Number((total - balance) / 480.0).toFixed(1);

        console.log("use :", use);
        // console.log("vacationTotal :", vacationTotal);
        // console.log("vacationBalance :", vacationBalance);
        // console.log("vacationUse :", vacationUse);
      }
      let iDay = 0.0;
      let iHour = 0.0;
      let iDaySick = 0.0;
      let { tmpTime, tmpSick } = 0;

      if (vacationData) {
        vacationData.map((item, index) => {
          // console.log("T4181_TYPE :", item.T4181_TYPE);
          //ลาพักร้อน
          if (["2", "พ"].includes(item.T4181_TYPE)) {
            iDay = iDay + Number(item.T4181_LDAY);
            iHour = iHour + Number(item.T4181_LHOU.substring(0, 2));
            // console.log("iDay :", iDay);
            // console.log("iHour :", iHour);
            //ป่วย
          } else if (["1"].includes(item.T4181_TYPE)) {
            iDaySick = iDaySick + Number(item.T4181_LDAY);
            // console.log("iDaySick :", iDaySick);
          }
        });
      }
      // iHour = 4;
      tmpTime = iDay * 480 + iHour * 60;
      let vacationPre = Number(tmpTime / 480.0).toFixed(1);
      let tmpTotal = Number((balance - tmpTime) / 480.0).toFixed(1);
      tmpSick = iDaySick;

      // console.log("tmpTime :", tmpTime);
      // console.log("Pre Approve :", vacationPre);
      // console.log("tmpTotal :", tmpTotal);
      // console.log("tmpSick :", tmpSick);

      const userVacation = {
        userId: data.userResult.user_eid,
        startDateVacation: startDateVacation,
        vacationTotal: vacationTotal,
        vacationBalance: vacationBalance,
        vacationUse: vacationUse,
        vacationPre: vacationPre,
        tmpTotal: tmpTotal,
        tmpSick: tmpSick,
      };

      dispatch({
        type: SETUP_USER_LOAD_DATA,
        payload: {
          userDisplay: data.userResult,
          userTimestamp: data.userTimestamp[0],
          userVacation: userVacation,
          userVacationData: data.userVacation.data,
          // alertText: "โหลดข้อมูล สำเร็จ ...!!",
        },
      });
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const craeteUserLeave = async ({
    currentUserLeave,
    endPoint,
    alertText,
    liff,
  }) => {
    dispatch({ type: CREATE_LEAVE_BEGIN });
    console.log("currentUserLeave:", currentUserLeave);
    // console.log("userDisplay:", state.userDisplay);
    const {
      eid,
      dateStart,
      dateEnd,
      daysNumber,
      beginTime,
      endTime,
      hour,
      leaveType,
      leaveName,
      timeStamp,
      profileLine,
      OS,
      isfullDay,
    } = currentUserLeave;
    try {
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/${endPoint}`,
        {
          eid,
          dateStart,
          dateEnd,
          daysNumber,
          beginTime,
          endTime,
          hour,
          leaveType,
          timeStamp: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          CompanyCode: state.userDisplay.userCompanyCode,
          leaveFrom: "Line App",
        }
      );
      // console.log(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
      console.log("data.dataResult:", data.dataResult);
      console.log("OS", OS);
      if (status === 201) {
        dispatch({
          type: CREATE_LEAVE_SUCCESS,
          payload: { alertText },
        });
        let tmpBalance = Number(state.userVacation.tmpTotal);
        if (["2", "พ"].includes(leaveType)) {
          console.log(Number(daysNumber));
          console.log(Number(state.userVacation.tmpTotal));
          tmpBalance =
            Number(state.userVacation.tmpTotal) -
            (isfullDay ? Number(daysNumber) : 0.5);
        }
        console.log("tmpBalance :", tmpBalance);
        //flex
        // if (OS === "ios") {
        liff
          .sendMessages(
            flexMessageLeave({
              runno: data.dataResult.newRunno,
              userName: `${state.userDisplay.user_eid} : ${state.userDisplay.user_fullname}`,
              pictureUrl: profileLine.pictureUrl,
              leaveType: leaveType,
              leaveName: leaveName,
              daysNumber: isfullDay ? daysNumber : 0.5,
              tmpBalance: tmpBalance,
              timeStamp: timeStamp, //timeStamp
            })
          )
          .then(function () {
            //window.alert("success");
            clearAlert();
            liff.closeWindow();
          })
          .catch(function (error) {});
      }
    } catch (error) {
      dispatch({
        type: CREATE_LEAVE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        displayAlertText,
        setupUser,
        updatePassword,
        craeteUserLeave,
        loadData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
